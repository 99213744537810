@import '../../scss/libs/bootstrap/variables';
@import '../../scss/settings';
@import '../../scss/functions';
@import 'bootstrap/scss/mixins/breakpoints';

.intro {
    padding-top: rem-calc(45);
    padding-bottom: rem-calc(30);
    background: $cc-green;
    color: $white;
    width: 100%;
    overflow: hidden;
    position: relative;

    // Every form Intro
    &.form-intro {
        padding-top: rem-calc(28);
        padding-bottom: rem-calc(5);

        p {
            font-size: rem-calc(20);

            a {
                color: $dark-orange;
                font-weight: bold;
                text-decoration: underline;
            }
        }

        @include media-breakpoint-up(md) {
            padding-top: rem-calc(40);
            padding-bottom: rem-calc(30);
        }
    }

    @include media-breakpoint-up(lg) {
        padding-top: rem-calc(60);
        padding-bottom: rem-calc(70);
    }

    h1 {
        text-transform: uppercase;
        margin-bottom: rem-calc(10);

        @include media-breakpoint-up(md) {
            font-size: rem-calc(45);
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }

        & + p {
            font-weight: 300 !important;
            font-size: rem-calc(21) !important;

            @include media-breakpoint-up(md) {
                font-size: rem-calc(32) !important;
            }
        }
    }

    p {
        text-transform: none !important;
    }

    h2 {
        margin-top: rem-calc(20);
        color: white;
        text-transform: none;
        font-size: rem-calc(24);

        span {
            color: $dark-purple;
        }

        @include media-breakpoint-up(md) {
            font-size: rem-calc(38);
        }
    }

    .slider-wrap {
        margin: rem-calc(100) rem-calc(10) rem-calc(75) rem-calc(10);
        position: relative;

        @include media-breakpoint-up(md) {
            margin-top: rem-calc(140);
            margin-bottom: rem-calc(40);
        }
    }

    .button {
        &.main {
            margin-bottom: rem-calc(50);
            margin-left: auto;
            margin-right: auto;
            @include media-breakpoint-up(md) {
                margin-bottom: rem-calc(70);
            }

            @include media-breakpoint-up(lg) {
                margin-bottom: rem-calc(30);
            }
        }
    }

    .stripes {
        @include media-breakpoint-up(lg) {
            &:before,
            &:after {
                display: block;
                content: '';
                position: absolute;
                margin-top: -73px;
                width: 0;
                height: 0;
                border-style: solid;
                z-index: 1;

                @media screen and (min-width: 2000px) {
                    margin-top: -173px;
                }
            }

            &:before {
                right: 50%;
                border-width: 100px 0 100px 1000px;
                border-color: transparent transparent transparent $cc-green-darker;

                @media screen and (min-width: 2000px) {
                    border-width: 200px 0 200px 2000px;
                }
            }

            &:after {
                left: 50%;
                border-width: 100px 1000px 100px 0;
                border-color: transparent $cc-green-darker transparent transparent;

                @media screen and (min-width: 2000px) {
                    border-width: 200px 2000px 200px 0;
                }
            }
        }
    }
}
